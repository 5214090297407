import React from "react";
import {Link, useParams, useNavigate} from "react-router-dom";

import {CompanyEditComponent} from "ppm-react-authentication";
import {COMPANY_API_URL,USERS_COMPANY_API_URL,USERS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";
const PagesEditCompanies  = (props) =>{

    const {history} = props;
    const params = useParams(); 
     const navigate = useNavigate();
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        navigate("/pages-companies");
    }
    return (
        <>
            <div className="page-content">
                    <title>{props.t("COMPANY")}</title>

                <div className="mb-4">
                    <Breadcrumbs title="Company" breadcrumbItem={props.t("COMPANY") + " " + ( id ? props.t("MODIFICATION")  : props.t("ADD") )}/>
                    <Link className="btn btn-info" to={"/pages-companies"}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <CompanyEditComponent
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={COMPANY_API_URL}
                    apiUserUrl={USERS_API_URL}
                    apiUserCompanyUrl={USERS_COMPANY_API_URL}
                    addUrl="/pages-companies/add"
                    path="/pages-companies"
                />
            </div>
        </>
    )
}

export default withTranslation()(PagesEditCompanies)
